import { thunk } from "easy-peasy";
import {
  archiveToolAPICall,
  getClientConfigurationToolListAPICall,
  getExceptionToolListAPICall,
  getMasterToolListAPICall,
  getMissingToolsAPICall,
  getTenantInfoByPartNumberAPICall,
  getToolListDeltasAPICall,
  getToolStatusListAPICall,
  mergeAllDeltasToMasterAPICall,
  mergeMasterToolAPICall,
  removeEngineFromMasterToolAPICall,
  removeToolFromDeltaListAPICall,
  upsertMasterToolAPICall,
  upsertToolStatusAPICall,
} from "../../api/tools";
import {
  completeMasterToolFileUpload,
  uploadMasterToolFileToAzureBlobStorage,
} from "../../api/file";
import { connectToSignalRHub } from "../../utilities/azure";
import { throttle } from "lodash";
import { groupMasterToolList } from "../../utilities/tool";
import moment from "moment";
import Papa from "papaparse";
import { Platform } from "react-native";
import { exportQuoteSheetToCSV } from "../../utilities/general";
import { mapHeaderSearchJson } from "../../utilities/filter";
import { stringifyArrayForSequelize } from "../../utilities/string";

const thunks = {
  mergeAllToolDeltasToMasterThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await mergeAllDeltasToMasterAPICall({
        OEMID:
          helpers.getState().leap.uploadType == "all"
            ? ""
            : helpers.getState().leap.uploadType,
        EngineFamilyID:
          helpers.getState().leap.uploadEngineModel == "all"
            ? ""
            : helpers.getState().leap.uploadEngineModel,
      });
      actions.getToolListDeltasThunk();
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  getToolListDeltasThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getToolListDeltasAPICall({
        OEM: id == "All" || id == "all" ? "" : id,
      });
      await actions.groupDeltaToolListAction(response.data);
      await actions.setToolDeltaListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getToolStatusListThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getToolStatusListAPICall();
      await actions.setToolStatusListAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertToolStatusThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      await upsertToolStatusAPICall(helpers.getState().tool.toolStatusInput);
      actions.setToolStatusInputAction({
        id: "",
        OEMid: "",
        EngineFamilyID: "",
        TSCode: "",
        TSDescription: "",
      });

      actions.setAlertThunk({
        type: "success",
        message: "Tool Status added successfully",
      });
      // if (navigation !== undefined) {
      // 	navigation.goBack();
      // }
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  getMissingToolsThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getMissingToolsAPICall({
        OEMID: id,
        EngineFamilyID: helpers.getState().leap.uploadEngineModel,
      });
      actions.setMissingToolListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getClientConfigurationToolListThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    const {
      OEM,
      requestedToolList,
      engineFamilyID,
      enginevariantid,
      PageNumber,
      searchpartnumber,
    } = data;
    try {
      const response = await getClientConfigurationToolListAPICall({
        OEMID: OEM == "All" ? "" : OEM == "all" ? "" : OEM,
        EngineFamilyID:
          engineFamilyID == "All"
            ? ""
            : engineFamilyID == "all"
            ? ""
            : engineFamilyID,
        enginevariantid:
          enginevariantid == "All"
            ? ""
            : enginevariantid == "all"
            ? ""
            : enginevariantid,
        requestedToolList: requestedToolList,
        userid: helpers.getState().user.authenticatedUser.id,
        PageNumber:
          PageNumber || helpers.getState().tool.currentConfigGroupedPage,
        PageSize: 9,
        searchpartnumber:
          searchpartnumber ||
          helpers.getState().tool.enteredClientConfigurationSearchInput,
        searchjson: stringifyArrayForSequelize(
          helpers
            .getState()
            .tool.clientConfigToolHeaders.flatMap((header) =>
              header.subheaders
                ? header.subheaders.map(mapHeaderSearchJson)
                : [mapHeaderSearchJson(header)]
            )
        ),
      });
      await actions.groupClientConfigToolList(response.data);
      await actions.setClientConfigurationToolListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: "Error fetching client configuration tool list",
      });
    }
    actions.setLoadingAction(false);
  }),
  exportClientConfigurationCsvThunk: thunk(
    async (actions, payload, helpers) => {
      actions.setLoadingAction(true);

      const {
        tool: {
          clientConfigurationSearchInput,
          configFilter,
          requestedToolList,
        },
        leap: { uploadType, uploadEngineModel },
      } = helpers.getState();

      try {
        const response = await getClientConfigurationToolListAPICall({
          OEMID:
            uploadType == "All" ? "" : uploadType == "all" ? "" : uploadType,
          EngineFamilyID:
            uploadEngineModel == "All"
              ? ""
              : uploadEngineModel == "all"
              ? ""
              : uploadEngineModel,
          enginevariantid:
            configFilter == "All"
              ? ""
              : configFilter == "all"
              ? ""
              : configFilter,
          requestedToolList: requestedToolList
            .trim()
            .split(/[\n]+/)
            .filter((item) => item)
            .join(","),
          searchpartnumber: clientConfigurationSearchInput,
          searchjson: stringifyArrayForSequelize(
            helpers
              .getState()
              .tool.clientConfigToolHeaders.flatMap((header) =>
                header.subheaders
                  ? header.subheaders.map(mapHeaderSearchJson)
                  : [mapHeaderSearchJson(header)]
              )
          ),
        });

        exportQuoteSheetToCSV(
          response.data,
          `${helpers.getState().leap.type}_CONFIG_${
            helpers.getState().tool.configFilter
          }_CLIENT_CONFIGURATION`,
          requestedToolList
        );
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: "error",
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  getMasterToolListThunk: thunk(
    async (actions, { PageNumber, searchpartnumber }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const response = await getMasterToolListAPICall({
          OEMID:
            helpers.getState().leap.uploadType == "all"
              ? null
              : helpers.getState().leap.uploadType == "All"
              ? null
              : helpers.getState().leap.uploadType,
          EngineFamilyID:
            helpers.getState().leap.uploadEngineModel == "All"
              ? null
              : helpers.getState().leap.uploadEngineModel == "all"
              ? null
              : helpers.getState().leap.uploadEngineModel,
          userid: helpers.getState().user.authenticatedUser.id,
          PageNumber:
            PageNumber || helpers.getState().tool.masterToolGroupedPage,
          PageSize: 9,
          searchpartnumber:
            searchpartnumber ||
            helpers.getState().tool.enteredMasterListSearchInput,
          searchjson: stringifyArrayForSequelize(
            helpers
              .getState()
              .tool.masterToolHeaders.filter((header) => !header.edit)
              .flatMap((header) =>
                header.subheaders
                  ? header.subheaders.map(mapHeaderSearchJson)
                  : [mapHeaderSearchJson(header)]
              )
          ),
        });
        await actions.groupMasterToolListAction(response.data);
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: "error",
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  exportMasterToolsToCSVThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getMasterToolListAPICall({
        OEMID:
          helpers.getState().leap.uploadType == "all"
            ? null
            : helpers.getState().leap.uploadType == "All"
            ? null
            : helpers.getState().leap.uploadType,
        EngineFamilyID:
          helpers.getState().leap.uploadEngineModel == "All"
            ? null
            : helpers.getState().leap.uploadEngineModel == "all"
            ? null
            : helpers.getState().leap.uploadEngineModel,
        userid: helpers.getState().user.authenticatedUser.id,
        searchjson: stringifyArrayForSequelize(
          helpers
            .getState()
            .tool.masterToolHeaders.filter((header) => !header.edit)
            .flatMap((header) =>
              header.subheaders
                ? header.subheaders.map(mapHeaderSearchJson)
                : [mapHeaderSearchJson(header)]
            )
        ),
      });

      let preparedData = [];
      const groupedList = groupMasterToolList(response.data);

      groupedList.forEach((category) => {
        if (category.engines.length > 0) {
          const firstEngine = category.engines[0];

          const combinedRow = {
            id: category.id,
            OEMID: category.OEMID,
            oem: category.OEM,
            oemdescription: category.oemdescription,
            EngineFamilyID: category.EngineFamilyID,
            partNumber: category.partNumber,
            ENGINE: firstEngine.engine,
            TSCode: firstEngine.TSCode,
            basepartNumber: category.basepartNumber,
            qualification: firstEngine.QualifiedRevision,
            crtlReplacementPartNumber: firstEngine.SupersededReplacedby,
            crtlReplacementStatusCode: firstEngine.SupersededReplacedbyTSCode,
            crtlReplacementQualification:
              firstEngine.SupersededReplacedbyQualification,
            overridePartNumber: firstEngine.overridePartNumber,
            overrideTSCode: firstEngine.overrideTSCode,
            overrideQualification: firstEngine.overrideQualification,
            SupersededReplacedbyQualification:
              firstEngine.SupersededReplacedbyQualification,
            partDescription: category.partDescription,
            StatusCodeDescription: category.StatusCodeDescription,
            QualifiedRevision: category.QualifiedRevision,
            SupersededReplacedby: category.SupersededReplacedby,
            SupercededReplacedbyTSCode: category.SupercededReplacedbyTSCode,
            FnFCode: category.FnFCode,
            LastRevision: category.LastRevision,
            Comments: category.Comments,
            ServiceBulletin: category.ServiceBulletin,
          };

          preparedData.push(combinedRow);

          category.engines.slice(1).forEach((subCat) => {
            preparedData.push({
              id: "",
              OEMID: "",
              oem: "",
              oemdescription: "",
              EngineFamilyID: "",
              partNumber: category.partNumber,
              ENGINE: subCat.engine,
              TSCode: subCat.TSCode,
              basepartNumber: subCat.basepartNumber,
              qualification: subCat.QualifiedRevision,
              crtlReplacement: subCat.SupersededReplacedby,
              crtlReplacementStatusCode: subCat.SupersededReplacedbyTSCode,
              crtlReplacementQualification:
                subCat.SupersededReplacedbyQualification,
              overridePartNumber: subCat.overridePartNumber,
              overrideTSCode: subCat.overrideTSCode,
              overrideQualification: subCat.overrideQualification,
              SupersededReplacedbyQualification:
                subCat.SupersededReplacedbyQualification,
              partDescription: category.partDescription,

              StatusCodeDescription: "",
              QualifiedRevision: "",
              SupersededReplacedby: "",
              SupercededReplacedbyTSCode: "",
              FnFCode: "",
              LastRevision: "",
              Comments: "",
              ServiceBulletin: "",
            });
          });
        } else {
          preparedData.push({
            id: category.id,
            OEMID: category.OEMID,
            oem: category.OEM,
            oemdescription: category.oemdescription,
            EngineFamilyID: category.EngineFamilyID,
            partNumber: category.partNumber,
            ENGINE: "",
            TSCode: "",
            qualification: "",
            crtlReplacement: "",
            crtlReplacementStatusCode: "",
            crtlReplacementQualification: "",
            overridePartNumber: "",
            overrideTSCode: "",
            overrideQualification: "",
            partDescription: category.partDescription,
            StatusCodeDescription: category.StatusCodeDescription,
            QualifiedRevision: category.QualifiedRevision,
            SupersededReplacedby: category.SupersededReplacedby,
            SupercededReplacedbyTSCode: category.SupercededReplacedbyTSCode,
            SupersededReplacedbyQualification:
              category.SupersededReplacedbyQualification,
            FnFCode: category.FnFCode,
            LastRevision: category.LastRevision,
            Comments: category.Comments,
            ServiceBulletin: category.ServiceBulletin,
            basepartNumber: category.basepartNumber,
          });
        }
      });

      const csv = Papa.unparse(preparedData);

      if (Platform.OS === "web") {
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment(new Date()).format("MM-DD-YYYY hh:mm:ss")}_MASTER_TOOL.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }

      actions.setLoadingAction(false);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getExceptionToolListThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getExceptionToolListAPICall({
        OEM: id,
      });
      actions.setExceptionToolListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertMasterToolThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      await upsertMasterToolAPICall(helpers.getState().tool.masterToolInput);

      await actions.getMasterToolListThunk({});
      actions.setMasterToolInputAction({
        id: null,
        OEMID: null,
        EngineFamilyID: null,
        partNumber: "",
        partDescription: "",
        TSCode: "",
        FandFCode: "",
        LastRevision: null,
        QualifiedRevision: null,
        SupersededReplaceBy: null,
        Comments: "",
        EngineVariant: "",
        internalComments: "",
        ServiceBulletin: null,
        overrideTSPartNumber: "",
        overrideTSCode: "",
        basepartNumber: "",
      });
      actions.setAlertThunk({
        type: "success",
        message: "Tool added successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  removeEngineFromMasterToolThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      for (let engine of helpers.getState().tool.masterToolEnginesToRemove) {
        await removeEngineFromMasterToolAPICall({
          ...engine,
          enginevariantid: engine.enginevariantsingularid,
        });
        actions.setAlertThunk({
          type: "success",
          message: `Engine ${engine.engine} removed from master tool successfully`,
        });
      }

      await actions.getMasterToolListThunk({});
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setMasterToolEnginesToRemoveAction([]);
    actions.setLoadingAction(false);
  }),
  mergeToolToMasterThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      await mergeMasterToolAPICall(helpers.getState().tool.mergeToolInput);

      // const response = await getToolListDeltasAPICall({
      // 	OEM: helpers.getState().leap.type,
      // });
      // actions.groupDeltaToolListAction(response.data);
      // actions.setToolDeltaListAction(response.data);
      actions.setAreYouSureReviewModalVisibleAction(false);

      if (
        helpers.getState().tool.currentReviewIndex <
        helpers.getState().tool.reviewList.length - 1
      ) {
        actions.setCurrentReviewIndexAction(
          helpers.getState().tool.currentReviewIndex + 1
        );
      } else {
        actions.setCurrentReviewIndexAction(0);
        actions.setReviewListModalActive(false);
        actions.setReviewListAction([]);

        actions.setLoadingAction(true);

        await actions.getToolListDeltasThunk();
        await actions.getMissingToolsThunk();
      }
      actions.setAlertThunk({
        type: "success",
        message: "Tool merged successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  removeToolFromDeltaListThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      await removeToolFromDeltaListAPICall({
        id: null, // id: helpers.getState().tool.masterToolInput.id,
        OEMID: helpers.getState().tool.mergeToolInput.OEMID,
        partNumber: helpers.getState().tool.mergeToolInput.partNumber,
        TSCode: helpers.getState().tool.mergeToolInput.TSCode,
      });
      if (
        helpers.getState().tool.currentReviewIndex <
        helpers.getState().tool.reviewList.length - 1
      ) {
        actions.setCurrentReviewIndexAction(
          helpers.getState().tool.currentReviewIndex + 1
        );
      } else {
        actions.setCurrentReviewIndexAction(0);
        actions.setReviewListModalActive(false);
        actions.setReviewListAction([]);
        await actions.getToolListDeltasThunk();
        await actions.getMissingToolsThunk();
      }

      actions.setAlertThunk({
        type: "success",
        message: "Tool removed successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  addToolToMasterListThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      // await upsertMasterToolAPICall({
      // 	id: null,
      // 	overrideTSPartNumber: "",
      // 	overrideTSCode: "",
      // 	...helpers.getState().tool.mergeToolInput,
      // });
      const tool = helpers.getState().tool.mergeToolInput;
      await upsertMasterToolAPICall({
        id: tool.id ?? "",
        OEMID: tool.OEMID,
        // OEMID: 2,
        EngineFamilyID:
          tool.EngineFamilyID ?? helpers.getState().tool.uploadEngineModel,
        partNumber: tool.partNumber,
        partDescription: tool.partDescription,
        TSCode: tool.TSCode,
        QualifiedRevision: tool.QualifiedRevision,
        SupersededReplaceBy: tool.SupersededReplaceBy,
        FandFCode: tool.FandFCode,
        LastRevision: tool.LastRevision,
        Comments: tool.Comments,
        ServiceBulletin: tool.ServiceBulletin,
        EngineVariant: tool.EngineVariant,
        internalComments: tool.internalComments,
        overrideTSPartNumber: tool.overrideTSPartNumber || "",
        overrideTSCode: tool.overrideTSCode || "",
      });

      if (
        helpers.getState().tool.currentReviewIndex <
        helpers.getState().tool.reviewList.length - 1
      ) {
        actions.setCurrentReviewIndexAction(
          helpers.getState().tool.currentReviewIndex + 1
        );
      } else {
        const response = await getMissingToolsAPICall({
          OEMID:
            helpers.getState().leap.uploadType == "All"
              ? ""
              : helpers.getState().leap.uploadType,
          EngineFamilyID: helpers.getState().leap.uploadEngineModel,
        });
        await actions.setMissingToolListAction(response.data);
        actions.setCurrentReviewIndexAction(0);
        actions.setReviewListModalActive(false);
        actions.setReviewListAction([]);
        await actions.getToolListDeltasThunk();
        await actions.getMissingToolsThunk();
      }

      actions.setAlertThunk({
        type: "success",
        message: "Tool added successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  mergeAllToMasterThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      const { groupedList } = helpers.getState().tool;
      for (let i = 0; i < groupedList.length; i++) {
        const { details } = groupedList[i];
        if (details.length == 1) {
          await mergeMasterToolAPICall({
            OEMID: details[0].OEMID || "",
            EngineFamilyID: details[0].engineFamilyID || "",
            partNumber: details[0].partNumber || "",
            partDescription: details[0].partDescription || "",
            TSCode: details[0].PartStatusCode || "",
            FandFCode: details[0].FnFCode || "",
            LastRevision: details[0].LastRevision || "",
            QualifiedRevision: details[0].QualifiedRevision || "",
            SupersededReplaceBy: details[0].ReplacedBy || "",
            Comments: details[0].Comments || "",
            EngineVariant: details[0].EngineVariant || "",
            internalComments: details[0].internalComments || "",
            ServiceBulletin: details[0].ServiceBulletin || "",
            PreviousTSCode: null,
            overrideTSPartNumber: details[0].LatestToolNumber || "",
            overrideTSCode: details[0].LatestToolNumberTSCode || "",
          });
        } else {
          for (let j = 0; j < details.length; j++) {
            if (details[j].RecordType == "MASTER RECORD") {
              await archiveToolAPICall({
                OEMID: details[j].OEMID || "",
                EngineFamilyID: details[j].engineFamilyID || "",
                partNumber: details[j].partNumber || "",
                TSCode: details[j].PartStatusCode || "",
                EngineVariant: details[j].ENGINE || "",
              });
            }
            if (details[j].RecordType == "NEW RECORD") {
              await mergeMasterToolAPICall({
                OEMID: details[j].OEMID || "",
                EngineFamilyID: details[j].engineFamilyID || "",
                partNumber: details[j].partNumber || "",
                partDescription: details[j].partDescription || "",
                TSCode: details[j].PartStatusCode || "",
                FandFCode: details[j].FnFCode || "",
                LastRevision: details[j].LastRevision || "",
                QualifiedRevision: details[j].QualifiedRevision || "",
                SupersededReplaceBy: details[j].ReplacedBy || "",
                Comments: details[j].Comments || "",
                EngineVariant: details[j].EngineVariant || "",
                internalComments: details[j].internalComments || "",
                ServiceBulletin: details[j].ServiceBulletin || "",
                PreviousTSCode: null,
                overrideTSPartNumber: details[j].LatestToolNumber || "",
                overrideTSCode: details[j].LatestToolNumberTSCode || "",
              });
            }
          }
        }
      }
      await actions.getToolListDeltasThunk();
      await actions.getMissingToolsThunk();
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  uploadGeneratedMasterToolFileThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      const connection = await connectToSignalRHub(
        process.env.REACT_APP_BACKEND_BASE_URL,
        helpers.getState().user.authenticatedUser.email,
        {
          onreconnecting: (error) =>
            actions.setIsReconnectingToAzureSignalRAction(true),
          onreconnected: (connectionId) =>
            actions.setIsReconnectingToAzureSignalRAction(false),
        }
      );

      const azureSignalRMethodName = "masterToolCsv";

      const throttledUpdate = throttle((updateFunction, payload) => {
        updateFunction(payload);
      }, 100); // Adjust the 100ms delay as needed

      connection.on(azureSignalRMethodName, (uploadData) => {
        throttledUpdate(actions.handleToolImportAction, uploadData);
      });

      const fileName = `MasterTool_${new Date().getTime()}.csv`;
      const response = await uploadMasterToolFileToAzureBlobStorage({
        base64: data,
        filename: fileName,
        mimeType: "text/csv",
      });

      console.log("NEW BLOB STORAGE", response);

      actions.setDynamicLoadingModalVisibleAction(true);

      // we need to wait 3 seconds and then setloading false
      // setTimeout(() => {
      // 	actions.setLoadingAction(false);
      // }, 3500);
      // actions.setLoadingAction(false);

      const completeUpload = await completeMasterToolFileUpload({
        mimeType: "text/csv",
        name: fileName,
        signalRMethodName: azureSignalRMethodName,
      });

      console.log("FINISHING UPLOAD ", completeUpload);

      actions.getMasterToolListThunk({});
      actions.setAlertThunk({
        type: "success",
        message: "Tools uploaded successfully",
      });
      actions.setDynamicLoadingModalVisibleAction(false);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
    // actions.setLoadingAction(false);
  }),

  getTenantInfoByPartNumberThunk: thunk(async (actions, data, helpers) => {
    try {
      const response = await getTenantInfoByPartNumberAPICall(data);
      actions.setTenantInfoByPartNumberAction(response.data);
      actions.setTenantInfoByPartNumberModalVisibleAction(true);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }
  }),
};

export default thunks;
